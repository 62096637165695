import {
  ROUTE_AUTH_CREATE,
  ROUTE_AUTH_RECOVER,
  ROUTE_TRADE,
  ROUTE_SEND,
  ROUTE_GUARDIAN,
  ROUTE_H5_APPROVE,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_SECURITY__EMAIL,
  ROUTE_ERROR,
  ROUTE_UNLOCK,
  ROUTE_AUTH_NEW,
  ROUTE_ENERGYBOOSTING,
  ROUTE_ASSET_DETAIL_QUOTE,
  ROUTE_SETTINGS,
  ROUTE_RECEIVE,
} from "./router-config";

const cmd_register = "register";
const cmd_recover = "recover";
const cmd_spotTrading = "spotTrading";
const cmd_send = "send";
const cmd_guardian = "guardian";
const cmd_approve = "approve";
const cmd_tasks = "tasks";
const cmd_editemail = "editemail";
const cmd_editGuardian = "editguardian";

export const routerMap: Map<string, string> = new Map([
  [cmd_register, ROUTE_CREATE_ACCOUNT], // /auth/create
  [cmd_recover, ROUTE_RECOVER_ACCOUNT], // /auth/recover
  [cmd_spotTrading, ROUTE_TRADE], // /h/trade
  [cmd_send, ROUTE_SEND], // /h/send
  [cmd_guardian, ROUTE_GUARDIAN], // /guardian
  [cmd_approve, ROUTE_H5_APPROVE], // /approve
  [cmd_editGuardian, ROUTE_GUARDIAN],
  [cmd_editemail, ROUTE_SECURITY__EMAIL],
]);

export const longExpirationCmds = [cmd_tasks];

export const NO_NAV_ROUTES: string[] = [
  ROUTE_CREATE_ACCOUNT,
  ROUTE_ERROR,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_AUTH_RECOVER,
  ROUTE_AUTH_CREATE,
  ROUTE_UNLOCK,
  ROUTE_AUTH_NEW,
  ROUTE_ENERGYBOOSTING,
  ROUTE_ASSET_DETAIL_QUOTE,
  ROUTE_SETTINGS,
  ROUTE_RECEIVE,
];

export type RouteWithSearchParam = {
  pathname: string;
  param: string;
};

export const H5_ROUTES: RouteWithSearchParam[] = [
  { pathname: ROUTE_ENERGYBOOSTING, param: "inviter" },
];

export const matchH5Route = (
  pathname: string,
  params: URLSearchParams
): boolean => {
  const macted = H5_ROUTES.some(
    (item) => item.pathname === pathname && !!params.get(item.param)
  );
  return macted;
};
