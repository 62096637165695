import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import { calcFtAssetBalanceInUSD } from "src/utils/zkkontosHelper";
import { FtAsset } from "src/type/zkkontos";
import { isNative } from "src/utils/helper";
import KontosNumber from "src/utils/KontosNumber";
import styled from "styled-components";
import { useAccount, useBalance } from "wagmi";
import { DEFAULT_DECIMAL } from "src/config";

const ItemTextContainer2 = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-end;
`;

const AssetSymbolText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;

  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
`;

const ChainSymbolText = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans;
  font-size: 12px;
`;

interface IProps {
  asset: FtAsset;
  displayPrecision?: number;
}

export const ExternalBalanceItem: React.FC<IProps> = ({
  asset,
  displayPrecision,
}) => {
  const walletAccount = useAccount();
  const walletBalance = useBalance({
    address: walletAccount.address,
    chainId: parseInt(asset.chainIndex),
    token: isNative(asset)
      ? undefined
      : (asset.address as `0x${string}` | undefined),
  });

  return (
    <ItemTextContainer2>
      <AssetSymbolText>
        {walletBalance.isFetching ? (
          <EllipsisPlaceholder />
        ) : (
          new KontosNumber(
            walletBalance.data?.value.toString(),
            walletBalance.data?.decimals
          ).toFormat(displayPrecision)
        )}
      </AssetSymbolText>
      <ChainSymbolText>
        {walletBalance.isFetching ? (
          <EllipsisPlaceholder />
        ) : (
          "$" +
          calcFtAssetBalanceInUSD(
            new KontosNumber(asset.usdPrice, DEFAULT_DECIMAL),
            new KontosNumber(
              walletBalance.data?.value.toString(),
              walletBalance.data?.decimals
            )
          ).toFormat(displayPrecision)
        )}
      </ChainSymbolText>
    </ItemTextContainer2>
  );
};
