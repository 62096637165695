import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BASIC_SHEET_INDEX } from "src/config";
import { KontosButton } from "../button/KontosButton";
import { forwardRef, useCallback, useRef } from "react";
import { openUrl } from "src/utils/helper";
import kontosRedirectIcon from "src/assets/icons/kontos-redirect.svg";
import tipsIcon from "src/assets/icons/tips.svg";

const Container = styled.div`
  border-radius: 16px;
  background: var(--White, #fff);
  max-width: 343px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .header-icon {
    width: 36px;
    //height: 24px;
  }

  .header-text {
    color: var(--Success, #10ce5c);
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }
`;

const Desc = styled.div`
  text-align: center;

  .desc-bold {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;
    line-height: 130%;
  }

  .desc-normal {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    line-height: 130%;
  }

  .desc-highlight {
    color: var(--Kontos-Blue, #413dec);
    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;
    line-height: 130%;
  }
`;

const Tip = styled.div`
  color: var(--Deep-400, #80868f);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  button {
    flex-grow: 1;
    height: 34px;
    line-height: 16px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;

    .my-loading {
      width: 24px;
      height: 24px;
      animation: dapp-connection-spin 1s linear infinite;

      @keyframes dapp-connection-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    .my-redirect {
      margin-left: 6px;
      width: 24px;
      height: 24px;
    }
  }
`;

interface IProps {
  accountName?: string;
}

export const UpgradingPopup = forwardRef<HTMLDivElement, IProps>(
  ({ accountName }, ref) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();

    const handleHelp = useCallback(() => {
      const url = "https://discord.gg/zecrey";
      openUrl(url);
    }, []);

    return (
      <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
        <Container ref={wrapperRef}>
          <Header>
            <img className="header-icon" src={tipsIcon} alt="" />
            <span className="header-text">
              {t("Kontos V2 Upgrade in Progress")}
            </span>
          </Header>

          <Desc>
            <span className="desc-bold">
              {t("Estimated completion: October 14, 2024 12:00 PM UTC")}
            </span>
            <br />
            <br />
            <span className="desc-normal">
              {t(
                "We appreciate your patience and continued support during this upgrade. Stay tuned for an enhanced Kontos experience!"
              )}
            </span>
            <br />
            <br />
            <span className="desc-highlight">
              {t("Rest assured, your assets are safe.")}
            </span>
          </Desc>

          <Tip>
            {t(
              "You can click the button below to join our Discord and stay updated on the latest developments."
            )}
          </Tip>

          <BtnGroup>
            <KontosButton $isOutlined onClick={handleHelp}>
              {t("I Need Help!")}
              <img className="my-redirect" src={kontosRedirectIcon} alt="" />
            </KontosButton>
          </BtnGroup>
        </Container>
      </Overlay>
    );
  }
);
