import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ROUTE_MAIN,
  ROUTE_PIN,
  ROUTE_AUTH_RECOVER,
  ROUTE_DEFAULT,
  ROUTE_TRADE,
  ROUTE_H5_SIGN,
  ROUTE_AUTH_NEW,
  ROUTE_AUTH_NEW_REGISTER,
  ROUTE_UNLOCK,
  ROUTE_BIND_WARN,
  ROUTE_SEND,
  ROUTE_GUARDIAN,
  ROUTE_H5_APPROVE,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_SECURITY__EMAIL,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_GENERAL_LANG,
  ROUTE_ASSET_DETAIL_QUOTE,
  ROUTE_DISCOVER,
  ROUTE_ENERGYBOOSTING,
  ROUTE_HOME,
  ROUTE_MARKETS,
  ROUTE_DISCOVER_EXPLORE,
  ROUTE_DISCOVER_FAVORITES,
  ROUTE_DISCOVER_TRENDINGS,
  ROUTE_ENERGYBOOSTING_LEADERBOARD,
  ROUTE_TRADE_SWAP,
  ROUTE_TRADE_SPOT,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_ACCOUNT_SWITCH,
  ROUTE_SETTINGS_PAYMENT,
  ROUTE_AI,
  ROUTE_RECEIVE,
} from "./router-config";
import RegisterKontos from "../pages/auth/RegisterKontos";
import { AI_QUERY_PARAM, RECOVERY } from "src/config";
import { useState, useEffect, useCallback } from "react";
import Home from "../pages/home/Home";
import Markets from "../pages/markets/Markets";
import { PINCode } from "../pages/sign-up/PIN-code/PINCode";
import ReactiveWelcome from "../pages/newAuth/Welcome";
import ReactiveCreateAccountFlow from "src/pages/newAuth/CreateAccountFlow";
import { OldUnlock } from "src/pages/sign-up/unlock/OldUnlock";
import BindWarning from "src/pages/error/BindWarning";
import { GuardianPage } from "src/pages/guardian/Guardian";
import ReactiveSendH5 from "src/pages/send-h5/SendH5";
import { observer } from "mobx-react";
import CreateAccount from "src/pages/newAuth/CreateAccount";
import RecoverAccount from "src/pages/newAuth/RecoverAccount";
import { Email } from "src/pages/security-email/Email";
import Layout, { NoNavLayout } from "src/components/layout";
import Settings from "src/pages/settings/Settings";
import { isSameKontosName } from "src/utils/zkkontosHelper";
import FirstScreen from "./FirstScreen";
import GeneralSetting from "src/pages/settings/general/GeneralSetting";
import LangSetting from "src/pages/settings/general/lang/LangSetting";
import EnergyBoosting from "src/pages/events/energy-boosting/EnergyBoosting";
import Sign from "src/pages/sign/Sign";
import { useStores } from "src/hooks/useStore";
import { matchH5Route } from "./router-map";
import { Flag } from "src/configs/featureFlags";
import { AssetDetailQuote } from "src/components/assets-detail/AssetDetailQuote";
import { Discover } from "src/pages/dapp/Discover";
import { DiscoverExploreTab } from "src/pages/dapp/explore/DiscoverExploreTab";
import { DiscoverFavoritesTab } from "src/pages/dapp/dapp-favorites/DiscoverFavoritesTab";
import { DiscoverTrendingTab } from "src/pages/dapp/trending/DiscoverTrendingTab";
import { EnergyBoostingMain } from "src/pages/events/energy-boosting/EnergyBoostingMain";
import { EbLeaderBoard } from "src/pages/events/energy-boosting/leader-board/EbLeaderBoard";
import { TradeDashboard } from "src/pages/trade/TradeDashboard";
import { TradeSwap } from "src/pages/trade/swap/TradeSwap";
import { TradeSpot } from "src/pages/trade/spot/TradeSpot";
import { PaymentMethod } from "src/pages/settings/payment/PaymentMethod";
import { AiScreen } from "src/pages/ai/AiScreen";
import ReceiveV2 from "src/pages/receive-v2/ReceiveV2";
// import tgManager from "src/store/managers/tgManager";

const authRouteArr = [
  ROUTE_AUTH_RECOVER,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_UNLOCK,
];

export const switchAccountPath = `${ROUTE_HOME}/${ROUTE_SETTINGS_ACCOUNT}${ROUTE_SETTINGS_ACCOUNT_SWITCH}`;

const pubKeyAllowedRoutes = [
  ROUTE_HOME,
  ROUTE_HOME + "/" + ROUTE_SETTINGS_ACCOUNT,
  switchAccountPath,
];

const fullScreenRouteArr = [ROUTE_AUTH_RECOVER, ROUTE_H5_SIGN, ROUTE_AI];

export const Router = observer(() => {
  const { userStore, uiStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentPath = location.pathname;
  const [ready, setReady] = useState(false);
  const routeStatus = searchParams.get("type") || "";

  const goToAuth = useCallback(() => {
    const checkWcBeforeAuth = () => {
      const wc = searchParams.get("wc");
      const expiryTimestamp = searchParams.get("expiryTimestamp");
      const relayProtocol = searchParams.get("relay-protocol");
      const symKey = searchParams.get("symKey");

      if (wc && expiryTimestamp && relayProtocol && symKey) {
        const { pathname } = location;
        const url = `${pathname}?wc=${wc}&expiryTimestamp=${expiryTimestamp}&relay-protocol=${relayProtocol}&symKey=${symKey}`;
        userStore.updateRouteAfterAuth(url);
      }
    };

    const checkDepositBeforeAuth = () => {
      const chainId = searchParams.get("chainId");
      const address = searchParams.get("address");
      const amount = searchParams.get("amount");
      const decimals = searchParams.get("decimals");

      if (chainId && address && amount && decimals) {
        const { pathname } = location;
        const url = `${pathname}?chainId=${chainId}&address=${address}&amount=${amount}&decimals=${decimals}`;
        userStore.updateRouteAfterAuth(url);
      }
    };

    const checkAiBeforeAuth = () => {
      const aiParam = searchParams.get(AI_QUERY_PARAM);

      if (aiParam) {
        const url = `${ROUTE_AI}?${AI_QUERY_PARAM}=${aiParam}`;
        userStore.updateRouteAfterAuth(url);
      }
    };

    const getAuthRoute = () => {
      const referralCode = searchParams.get("referralCode");

      if (typeof referralCode === "string") {
        return ROUTE_AUTH_NEW + "?referralCode=" + referralCode;
      }
      return ROUTE_AUTH_NEW;
    };

    checkWcBeforeAuth();
    checkAiBeforeAuth();
    checkDepositBeforeAuth();
    navigate(getAuthRoute());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, searchParams, userStore]);

  const checkRegister = useCallback(
    (route: string, withoutRoute: boolean = false) => {
      if (userStore.hasAccountInStorage()) {
        userStore.updateTargetRoute(route);
        navigate(ROUTE_UNLOCK);
      } else if (!withoutRoute) {
        navigate(route);
        userStore.updateTargetRoute("");
      }
    },
    [navigate, userStore]
  );

  const checkRecovering = useCallback(
    (route: string, name: string) => {
      const localKeys = userStore.storageKeys;
      if (localKeys?.length > 0) {
        const matchKey = localKeys.find((item) =>
          isSameKontosName(item.accountName, name)
        );
        const recoverings = userStore.accountsRecovering;
        const matchName = recoverings.find((item) =>
          isSameKontosName(item, matchKey?.accountName)
        );
        if (matchName) {
          goToAuth();
        } else {
          navigate(route);
        }
      } else {
        goToAuth();
      }
    },
    [goToAuth, navigate, userStore.accountsRecovering, userStore.storageKeys]
  );

  const checkForApp = useCallback(async () => {
    if (matchH5Route(location.pathname, searchParams)) {
      await userStore.initNetwork();
      userStore.restore(true, false);
      setReady(true);
      return;
    }

    await userStore.restore(true, false);
    const name = userStore.accountInfo?.name;
    if (name) {
      if (authRouteArr.includes(location.pathname)) {
        checkRegister(location.pathname + location.search || "", true);
      } else {
        checkRecovering(location.pathname + location.search, name);
      }
    } else {
      goToAuth();
    }
    setReady(true);
    return;
  }, [
    checkRecovering,
    checkRegister,
    goToAuth,
    location.pathname,
    location.search,
    searchParams,
    userStore,
  ]);

  useEffect(() => {
    if (userStore.isInited) {
      // check();
      checkForApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.isInited]);

  useEffect(() => {
    if (ready) {
      // network fail check
      if (userStore.noNetwork) {
        if (userStore.accountInfo) {
          navigate(ROUTE_HOME);
        } else {
          goToAuth();
        }
        return;
      }

      if (
        !userStore.accountInfo &&
        !authRouteArr.includes(location.pathname) &&
        !matchH5Route(location.pathname, searchParams)
      ) {
        goToAuth();
        return;
      }
      // pubkey check
      if (
        userStore.pubKeyException &&
        !authRouteArr.includes(location.pathname) &&
        !pubKeyAllowedRoutes.includes(location.pathname)
      ) {
        navigate(switchAccountPath);
        return;
      }
    }
  }, [
    goToAuth,
    location.pathname,
    navigate,
    ready,
    searchParams,
    userStore.accountInfo,
    userStore.noNetwork,
    userStore.pubKeyException,
  ]);

  useEffect(() => {
    // check if is recovering account
    if (location.pathname === ROUTE_RECOVER_ACCOUNT && !routeStatus) {
      userStore.restore(true);
    }
  }, [location.pathname, routeStatus, userStore]);

  return (
    <NoNavLayout>
      <FirstScreen isMobile={!uiStore.isOverWidth} />
    </NoNavLayout>
  );

  // return fullScreenRouteArr.includes(currentPath) ? (
  //   <Routes>
  //     <Route
  //       path={ROUTE_AUTH_RECOVER}
  //       element={<RegisterKontos type={RECOVERY} />}
  //     />
  //     <Route path={ROUTE_H5_SIGN} element={<Sign />} />

  //     <Route
  //       path={ROUTE_AI}
  //       element={
  //         // tgManager.isTg() ? (
  //         //   <NoNavLayout>
  //         //     <AiScreen />
  //         //   </NoNavLayout>
  //         // ) : (
  //         //   <Navigate to={ROUTE_HOME} />
  //         // )
  //         <NoNavLayout>
  //           <AiScreen />
  //         </NoNavLayout>
  //       }
  //     />
  //   </Routes>
  // ) : (
  //   <Layout>
  //     <Routes>
  //       <Route path={ROUTE_DEFAULT} element={<Navigate to={ROUTE_HOME} />} />
  //       <Route path={ROUTE_TRADE + "/*"} element={<TradeDashboard />}>
  //         <Route index element={<Navigate to={ROUTE_TRADE_SPOT} />} />
  //         <Route path={ROUTE_TRADE_SPOT + "/*"} element={<TradeSpot />} />
  //         <Route path={ROUTE_TRADE_SWAP + "/*"} element={<TradeSwap />} />
  //         <Route path={"*"} element={<Navigate to={ROUTE_TRADE_SPOT} />} />
  //       </Route>

  //       <Route path={ROUTE_PIN} element={<PINCode />} />
  //       <Route path={ROUTE_MAIN + "/*"} element={<Home />} />
  //       {/* new ui*/}
  //       <Route path={ROUTE_HOME + "/*"} element={<Home />} />
  //       <Route path={ROUTE_MARKETS} element={<Markets />} />
  //       <Route path={ROUTE_ASSET_DETAIL_QUOTE} element={<AssetDetailQuote />} />
  //       <Route path={ROUTE_AUTH_NEW} element={<ReactiveWelcome />} />
  //       <Route
  //         path={ROUTE_AUTH_NEW_REGISTER}
  //         element={<ReactiveCreateAccountFlow />}
  //       />
  //       <Route path={ROUTE_CREATE_ACCOUNT} element={<CreateAccount />} />
  //       <Route path={ROUTE_RECOVER_ACCOUNT} element={<RecoverAccount />} />
  //       <Route path={ROUTE_UNLOCK} element={<OldUnlock />} />
  //       <Route path={ROUTE_BIND_WARN} element={<BindWarning />} />
  //       <Route path={ROUTE_SEND} element={<ReactiveSendH5 />} />
  //       <Route
  //         path={ROUTE_H5_APPROVE}
  //         element={<GuardianPage init="approve" />}
  //       />
  //       <Route
  //         path={ROUTE_GUARDIAN}
  //         element={<GuardianPage init="editGuardian" />}
  //       />
  //       <Route path={ROUTE_SECURITY__EMAIL} element={<Email />} />
  //       <Route path={ROUTE_SETTINGS + "/*"} element={<Settings />} />
  //       <Route path={ROUTE_SETTINGS_GENERAL} element={<GeneralSetting />} />
  //       <Route path={ROUTE_SETTINGS_GENERAL_LANG} element={<LangSetting />} />
  //       <Route
  //         path={ROUTE_SETTINGS + "/" + ROUTE_SETTINGS_PAYMENT}
  //         element={<PaymentMethod onDone={() => navigate(-1)} isFullPage />}
  //       />

  //       <Route path={ROUTE_DISCOVER + "/*"} element={<Discover />}>
  //         <Route index element={<Navigate to={ROUTE_DISCOVER_EXPLORE} />} />
  //         <Route
  //           path={ROUTE_DISCOVER_EXPLORE + "/*"}
  //           element={<DiscoverExploreTab />}
  //         />
  //         <Route
  //           path={ROUTE_DISCOVER_FAVORITES + "/*"}
  //           element={<DiscoverFavoritesTab />}
  //         />
  //         <Route
  //           path={ROUTE_DISCOVER_TRENDINGS + "/*"}
  //           element={<DiscoverTrendingTab />}
  //         />
  //         <Route path={"*"} element={<DiscoverExploreTab />} />
  //       </Route>

  //       {/* H5 Events */}
  //       {Flag.eb && (
  //         <Route
  //           path={ROUTE_ENERGYBOOSTING + "/*"}
  //           element={<EnergyBoosting />}
  //         >
  //           <Route index element={<EnergyBoostingMain />} />
  //           <Route
  //             path={ROUTE_ENERGYBOOSTING_LEADERBOARD + "/*"}
  //             element={<EbLeaderBoard />}
  //           />
  //           <Route
  //             path={"*"}
  //             element={<Navigate to={ROUTE_ENERGYBOOSTING} />}
  //           />
  //         </Route>
  //       )}

  //       {/* Receive */}
  //       <Route path={ROUTE_RECEIVE} element={<ReceiveV2 />} />

  //       <Route path="*" element={<Navigate to={ROUTE_DEFAULT} />} />
  //     </Routes>
  //   </Layout>
  // );
});
