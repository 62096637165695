import { reaction, makeAutoObservable, runInAction } from "mobx";
import {
  KontosClient,
  KontosKey,
  KontosKeyHelper,
  KontosQueryCli,
  NetworkInfo,
  StorageKontosKey,
} from "@zkkontos/kontos-sdk";
import { DEFAULT_DECIMAL, EXPLORER_KONTOS_URL, KONTOS_URL } from "../config";
import localKeeper, { OnboardingType } from "./localKeeper";
import { KontosCtx } from "src/apis/types";
import {
  getNoSuffixKontosName,
  isMobileDevice,
  withTimeout,
} from "src/utils/helper";
import { account } from "@zkkontos/kontos-sdk/src/api/aaApi";
import {
  RespAccountBalancesV2,
  accountbalancesV2,
} from "@zkkontos/kontos-sdk/src/api/assetApi";
import { loadingStore } from "./loadingStore";
import toast from "src/components/toast/Toast";
import {
  Account,
  AccountBalances,
  ChainConfig,
  FtAsset,
  toKontosAccount,
} from "src/type/zkkontos";
import {
  getFtAssetsFromBalances,
  getTimeDifference,
  isSameKontosName,
} from "src/utils/zkkontosHelper";
import { LANG } from "src/i18n/lang";
import i18n from "src/i18n/i18n";
import KontosNumber from "src/utils/KontosNumber";
import { t } from "i18next";
import { RootStore } from "./RootStore";
import { callAaaccounts } from "src/apis/explorer-apis";
import { SheetView } from "./SheetStore";

const CRON_ACCOUNT_INTERVAL = 60000;
const CRON_BALANCE_INTERVAL = 20000;
const NO_ACCOUNT_RETRY_TIME = 2;

export class UserStore {
  rootStore: RootStore;
  kontosCli?: KontosClient;
  kontosQueryCli?: KontosQueryCli;
  accountsRecovering: string[] = [];
  kontosCtx?: KontosCtx;
  isMobile: boolean = isMobileDevice();
  accountInfo?: Account;
  accountBalances?: AccountBalances;
  unlockAmount: number = localKeeper.getUnlockAmount();
  targetRoute?: string;
  storeAccountSelectedIndex: number = 0;
  toBindAccount?: string;
  storageKeys: StorageKontosKey[] = [];
  lang: string = LANG.en.name;
  blockChainAccounts:
    | {
        [key: string]: string;
      }
    | undefined;
  isInited: boolean = false;
  routeAfterAuth?: string;
  aaAccounts: { [key: string]: string } = {};
  accountInitFail: boolean = false;
  onboardingTodos: OnboardingType[] = [];
  pin?: string;

  reset = () => {
    this.kontosCli = undefined;
    this.accountsRecovering = [];
    this.accountInfo = undefined;
    this.accountBalances = undefined;
    this.targetRoute = undefined;
    this.routeAfterAuth = undefined;
    this.aaAccounts = {};
    this.onboardingTodos = [];
    this.pin = undefined;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    localKeeper.clearFtAssets();
    localKeeper.startOnboarding();
    localKeeper.checkLocalRecords();
    this.startTrackingAccountName();
    this.accountsRecovering = localKeeper.getAccountsRecovering() || [];
    this.storageKeys = localKeeper.getAllStorageKontosKey() || [];
    const lang = localKeeper.getLang() || navigator.language || LANG.en.name;
    this.updateLang(lang);
    this.isInited = true;
  }

  ensureAccountInfo = async (
    retryCount: number = NO_ACCOUNT_RETRY_TIME
  ): Promise<boolean> => {
    const { flag } = await this.executeWithAccountInfoRetry(
      () => {
        return true;
      },
      () => {
        return false;
      },
      retryCount
    );
    return flag;
  };

  executeWithAccountInfoRetry = async <T, K>(
    todo?: () => Promise<T> | T,
    fallback?: () => Promise<K> | K,
    retryCount: number = NO_ACCOUNT_RETRY_TIME
  ) => {
    if (!this.accountInfo) {
      let count = 0;
      while (count < retryCount) {
        try {
          await this.fetchAndUpdateAccountInfoFromChain();
          break;
        } catch {
          count++;
        }
      }
      if (count === retryCount) {
        return { flag: false, data: await fallback?.() };
      }
    }
    return { flag: true, data: await todo?.() };
  };

  fetchAndUpdateAccountBalances = async (accountName?: string) => {
    const name: string | undefined = accountName
      ? accountName
      : localKeeper.getStorageKontosKeyWithCheck()?.accountName;
    if (name) {
      try {
        const resp = await accountbalancesV2(
          { account: name },
          EXPLORER_KONTOS_URL
        );
        runInAction(() => {
          this.accountBalances = resp;
        });
        return resp;
      } catch (e) {
        console.log("Failed to fetch account balances", e);
      }
    } else {
      console.log("No storage key for fetching account balances");
    }
  };

  fetchAndUpdateAccountInfoFromChain = async (accountName?: string) => {
    const name: string | undefined = accountName
      ? accountName
      : localKeeper.getStorageKontosKeyWithCheck()?.accountName;
    if (name !== undefined) {
      try {
        const queryCli = await this.getKontosQueryCli();
        const smartAccount = await queryCli.getSmartAccountByName(name);
        const resp = toKontosAccount(smartAccount);
        runInAction(() => {
          this.accountInfo = resp;
        });
        return resp;
      } catch (e) {
        console.log("Failed to fetch account from chain", e);
      }
    } else {
      console.log("No storage key for fetching account from chain");
    }
  };

  // add cron job here
  cronjob = async () => {
    this.fetchAndUpdateAccountBalances(this.accountInfo?.name);

    setInterval(async () => {
      this.fetchAndUpdateAccountInfoFromChain(this.accountInfo?.name);
    }, CRON_ACCOUNT_INTERVAL);

    setInterval(async () => {
      this.fetchAndUpdateAccountBalances(this.accountInfo?.name);
    }, CRON_BALANCE_INTERVAL);
  };

  initNetwork = async () => {
    try {
      return await this.getKontosQueryCli();
    } catch (e) {}
  };

  restore = async (
    needBalance?: boolean,
    showLoading: boolean = true,
    retryCount: number = 0
  ) => {
    try {
      showLoading && loadingStore.showLoading();
      this.accountsRecovering = localKeeper.getAccountsRecovering() || [];
      this.storageKeys = localKeeper.getAllStorageKontosKey() || [];

      const storageKey = localKeeper.getStorageKontosKeyWithCheck();
      if (!storageKey) {
        await this.initNetwork();
        console.log("no storage key");
        return;
      }

      const getAccountFromChain = async () => {
        const queryCli = await this.getKontosQueryCli();
        const smartAccount = await queryCli.getSmartAccountByName(
          storageKey.accountName
        );
        return toKontosAccount(smartAccount);
      };

      const getAccountBalances = () =>
        accountbalancesV2(
          { account: storageKey.accountName },
          EXPLORER_KONTOS_URL
        );

      const getAccount = getAccountFromChain;

      const toFetch = needBalance
        ? [getAccount(), getAccountBalances()]
        : [getAccount()];

      const results = await Promise.allSettled(toFetch);

      for (const [index, result] of results.entries()) {
        if (result.status === "fulfilled") {
          if (index === 0) {
            this.updateAccountInfo(result.value as Account);
            runInAction(() => {
              this.accountInitFail = false;
            });
          } else if (index === 1) {
            this.updateAccountBalances(result.value as RespAccountBalancesV2);
          }
        } else {
          if (index === 0) {
            if (retryCount > 0) {
              await this.restore(needBalance, showLoading, retryCount - 1);
              return;
            } else if (this.noNetwork) {
              this.updateAccountInfo(toKontosAccount(storageKey.accountName));
            }
            runInAction(() => {
              this.accountInitFail = true;
            });
            console.error(result.reason);
            toast({ type: "error", text: result.reason.toString() });
          } else {
            console.error(result.reason);
            toast({ type: "error", text: result.reason.toString() });
          }
        }
      }

      // set onboarding todos
      runInAction(() => {
        this.onboardingTodos =
          localKeeper
            .getOnboardingStatus()
            ?.filter((item) => item.finished === false)
            ?.map((item) => item.type) || [];
      });
    } catch (e) {
      console.error(e);
      const errorMessage =
        e instanceof Error
          ? e.message
          : "An unknown error occurred when restore";
      if (!errorMessage.startsWith("cannot new kontos query client")) {
        toast({ type: "error", text: errorMessage });
      }
    } finally {
      showLoading && loadingStore.hideLoading();
      if (!this.accountInitFail) {
        this.cronjob();
      }
    }
  };

  checkPubKey = async (toVerifyPubKey: string): Promise<boolean> => {
    try {
      const accountName = this.accountInfo?.name;
      const queryCli = await this.getKontosQueryCli();
      const account = await queryCli.getSmartAccountByName(accountName!);
      if (
        toVerifyPubKey.toLocaleLowerCase() ===
        account.pubKey.toLocaleLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  fetchAndSetBlockChainAccounts = async () => {
    if (!this.accountInfo) return;
    try {
      const resp = await account(
        { account: this.accountInfo.name },
        EXPLORER_KONTOS_URL
      );
      this.blockChainAccounts = resp.blockChainAccounts;
    } catch (e) {
      console.log("Exception with fetching block chain accounts");
    }
  };

  fetchAndSetAaAccounts = async (): Promise<
    | {
        [key: string]: string;
      }
    | undefined
  > => {
    if (!this.accountInfo) return;
    try {
      const { aaAccounts } = await callAaaccounts(this.accountInfo.name);
      this.aaAccounts = aaAccounts;
      return aaAccounts;
    } catch (e) {
      console.log("Exception with fetching aa accounts");
    }
  };

  // Fetch new data when account name changes
  startTrackingAccountName = () => {
    reaction(
      () => this.accountInfo?.name,
      () => {
        this.fetchAndSetBlockChainAccounts();
        this.fetchAndSetAaAccounts();
      }
    );
  };

  getAccountInfo = (): Account | undefined => {
    return this.accountInfo;
  };

  updateAccountInfo = (data: Account | undefined) => {
    this.accountInfo = data;
  };

  updateAccountBalances = (data: AccountBalances | undefined) => {
    this.accountBalances = data;
  };

  updateAccountsRecovering = (arr: string[]) => {
    localKeeper.updateAccountsRecovering(arr);
    this.accountsRecovering = arr;
  };

  resetStorage = () => {
    localKeeper.resetAccount();
    this.rootStore.spotStore.reset();
    this.rootStore.swapStore.reset();
    this.reset();
  };

  updateKey = async (key: KontosKey, seed: string) => {
    await localKeeper.updateKontosKey(key);
    await this.reconstructKontosCli(seed);
  };

  newKontosCli = async (
    seed: string,
    accountName: string,
    recoverPassword: string,
    guardians: string[] = [],
    actionThreshold: number = 0,
    inviter: string,
    actionType: number = 0,
    emailHashes: string[] = [],
    network: NetworkInfo = { rpcUrl: KONTOS_URL }
  ): Promise<KontosClient> => {
    let cli: KontosClient;
    try {
      // if (emailHashes.length === 0) {
      //   cli = await KontosClient.newKontosClientWithNewAccount(
      //     seed,
      //     accountName,
      //     recoverPassword,
      //     guardians,
      //     actionThreshold,
      //     inviter,
      //     actionType,
      //     network
      //   );
      // } else {
      cli = await KontosClient.newKontosClientWithNewAccountV2(
        seed,
        getNoSuffixKontosName(accountName),
        guardians,
        emailHashes,
        actionThreshold,
        inviter,
        actionType,
        network
      );
      // }
      await localKeeper.updateKontosKey(cli.key);
      this.kontosCli = cli;
      this.kontosQueryCli = await KontosQueryCli.newForQuery(network);
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      } else {
        throw new Error("unknown error");
      }
    }
    return this.kontosCli!;
  };

  getKontosQueryCli = async (
    network: NetworkInfo = { rpcUrl: KONTOS_URL }
  ): Promise<KontosQueryCli> => {
    if (this.kontosQueryCli === undefined) {
      try {
        this.kontosQueryCli = await withTimeout(
          KontosQueryCli.newForQuery(network),
          15000
        );
      } catch (e) {
        if (e instanceof Error) {
          throw new Error("cannot new kontos query client:", e);
        } else {
          throw new Error("unknown error");
        }
      }
    }
    return this.kontosQueryCli!;
  };

  reconstructKontosCli = async (
    seed: string,
    isRecovery?: boolean,
    network: NetworkInfo = { rpcUrl: KONTOS_URL }
  ): Promise<KontosClient> => {
    const storageKontosKey = localKeeper.getStorageKontosKey(
      isRecovery ? undefined : this.accountName
    );
    if (!storageKontosKey) {
      throw new Error("empty data");
    }
    try {
      console.log("reconstructKontosCli");
      const key = await KontosKeyHelper.fromStorageKontosKey(
        storageKontosKey,
        seed
      );
      key.accountName = getNoSuffixKontosName(key.accountName);
      this.kontosCli = await KontosClient.newKontosClient(key, network);
      this.kontosQueryCli = await KontosQueryCli.newForQuery(network);
      return this.kontosCli;
    } catch (e) {
      console.warn("reconstructKontosCli error for first try", e);
      const key = await KontosKeyHelper.fromStorageKontosKey(
        storageKontosKey,
        seed
      );
      key.accountName = getNoSuffixKontosName(key.accountName);
      this.kontosCli = await KontosClient.newKontosClient(key, network);
      this.kontosQueryCli = await KontosQueryCli.newForQuery(network);
      return this.kontosCli;
    }
  };

  // TODO: Seed
  getKontosCli = async (): Promise<KontosClient> => {
    if (!this.kontosCli) {
      throw new Error("no seed in session, you need to unlock first");
    }
    return this.kontosCli!;
  };

  hasAccountInStorage = (): boolean => {
    const key = localKeeper.getStorageKontosKey();
    return !!key;
  };

  getStorageKontosKey = (): StorageKontosKey => {
    const key = localKeeper.getStorageKontosKey();
    return key!;
  };

  updateUnlockAmount = (amount: number) => {
    localKeeper.updateUnlockAmount(amount);
    this.unlockAmount = amount;
  };

  getTargetRoute = (): string | undefined => {
    return this.targetRoute;
  };

  updateTargetRoute = (route: string) => {
    this.targetRoute = route;
  };

  switchAccount = async (index: number, isRecovery = false) => {
    localKeeper.switchAccount(index);
    this.storeAccountSelectedIndex = index;
    this.kontosCli = undefined;
    if (isRecovery) {
      return;
    }
    try {
      loadingStore.showLoading();
      await this.rootStore.dappConnectStore.disConnectAll();
      await this.restore(true);
      await this.fetchAndSetAaAccounts();
    } catch (e) {
      console.log("Exception occured when switching account", e);
    } finally {
      loadingStore.hideLoading();
    }
  };

  removeAccount = (name: string, force?: boolean) => {
    const currentName = this.accountInfo?.name;
    if (force || (currentName && !isSameKontosName(currentName, name))) {
      localKeeper.removeAccount(name);
      this.storageKeys = localKeeper.getAllStorageKontosKey() || [];
    }
    // Check recovering accounts
    localKeeper.removeRecoveringAccount(name);
    this.accountsRecovering = localKeeper.getAccountsRecovering() || [];
    if (force) {
      this.restore();
    }
  };

  //  clear email recovering accounts since it's not allowed to approve this in home page
  clearEmailRecoveringAccounts = () => {
    const recoverings = this.accountsRecovering || [];
    const recoveringsWithEmail = localKeeper.getEmailAccountsRecovering() || [];

    const recoveringsWithEmailSet = new Set(recoveringsWithEmail);

    const filteredRecoverings = recoverings.filter((account) =>
      recoveringsWithEmailSet.has(account)
    );

    filteredRecoverings.forEach((item) => {
      this.removeAccount(item);
    });

    localKeeper.updateEmailAccountsRecovering([]);
  };

  getToBindAccount = (): string | undefined => {
    return this.toBindAccount;
  };

  updateToBindAccount = (account: string) => {
    this.toBindAccount = account;
  };

  updateLang = async (lang: string) => {
    localKeeper.updateLang(lang);
    this.lang = lang;
    i18n.changeLanguage(lang);
  };

  updateRouteAfterAuth = (route?: string) => {
    this.routeAfterAuth = route;
  };

  filterUserHoldingsChainsWithSpecificChains(
    specificChains: ChainConfig[]
  ): ChainConfig[] {
    const userHoldFtAssetsChains = this.userHoldings?.map(
      (ftAsset) => ftAsset?.chainIndex.toLocaleLowerCase()
    );
    return specificChains.filter((chain) =>
      userHoldFtAssetsChains.includes(chain?.chainIndex.toLocaleLowerCase())
    );
  }

  unlock = (
    unlockCallback?: (cli: KontosClient) => void,
    needExecute?: boolean
  ): boolean => {
    if (!this.kontosCli) {
      this.rootStore.sheetStore.openSheet(SheetView.Unlock, { unlockCallback });
      return false;
    }
    if (needExecute) {
      unlockCallback?.(this.kontosCli);
    }
    return true;
  };

  retryConnectToNetwork = async () => {
    if (this.kontosQueryCli) {
      await this.restore();
    } else {
      await this.getKontosQueryCli();
      await this.restore();
    }
  };

  markOnboardingFinished = (type: OnboardingType) => {
    localKeeper.markOnboardingFinished(type);
    this.onboardingTodos =
      localKeeper
        .getOnboardingStatus()
        ?.filter((item) => item.finished === false)
        ?.map((item) => item.type) || [];
  };

  getPin = () => {
    return this.pin;
  };

  setPin = (seed: string) => {
    this.pin = seed;
  };

  get guardianChanged(): boolean {
    if (this.accountInfo && this.accountInfo.updateGuardiansDeadline > 0) {
      if (this.accountInfo.futureGuardians?.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailGuardianChanged(): boolean {
    if (this.accountInfo && this.accountInfo.updateGuardiansDeadline > 0) {
      if (this.accountInfo.futureEmailGuardians?.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get updateGuardianDeadlineTimeAgo(): string {
    if (this.accountInfo?.updateGuardiansDeadline) {
      return getTimeDifference(this.accountInfo?.updateGuardiansDeadline, t);
    }
    return "";
  }

  get kontosName(): string {
    if (this?.accountInfo?.name) {
      return this.accountInfo.name + ".os";
    } else {
      return "";
    }
  }

  get accountStorageKey(): StorageKontosKey | undefined {
    if (this.accountInfo && this.storageKeys) {
      return this.storageKeys.find((item) =>
        isSameKontosName(item.accountName, this.accountInfo?.name)
      );
    }
    return undefined;
  }

  get pubKeyException(): boolean {
    if (this.noNetwork) {
      return false;
    }
    if (this.accountInfo && this.accountStorageKey) {
      if (
        this.accountInfo.pubKey !==
          this.accountStorageKey.pubKeyData.compressedPubKey &&
        !this.accountsRecovering.includes(this.accountInfo.name)
      ) {
        return true;
      }
    }
    return false;
  }

  get balanceInUSD(): string | undefined {
    return this.accountBalances
      ? new KontosNumber(
          this.accountBalances?.totalUsd,
          DEFAULT_DECIMAL
        ).toFormat()
      : undefined;
  }

  get userHoldings(): FtAsset[] {
    return this.accountBalances
      ? getFtAssetsFromBalances(this.accountBalances)
      : [];
  }

  get userHoldingsInWhitelist(): FtAsset[] {
    return this.userHoldings.filter((item) => item.isWhitelist === true);
  }

  get userHoldingsInBlacklist(): FtAsset[] {
    return this.userHoldings.filter((item) => item.isWhitelist === false);
  }

  get userHoldingsCanBeUsed(): FtAsset[] {
    return this.userHoldings.filter(
      (item) => item.isWhitelist === true && item.isUsed === true
    );
  }

  get userHoldingsInWhitelistInUsd(): KontosNumber {
    return this.userHoldingsInWhitelist.reduce(
      (acc: KontosNumber, cur: FtAsset) =>
        acc.add(cur.totalUsd, DEFAULT_DECIMAL),
      new KontosNumber(0)
    );
  }

  get userHoldingsCanBeUsedInUsd(): KontosNumber {
    return this.userHoldingsCanBeUsed.reduce(
      (acc: KontosNumber, cur: FtAsset) =>
        acc.add(cur.totalUsd, DEFAULT_DECIMAL),
      new KontosNumber(0)
    );
  }

  get userHoldingsAvailableInUsd(): KontosNumber {
    return this.userHoldingsInWhitelistInUsd.minus(
      this.userHoldingsCanBeUsedInUsd
    );
  }

  get userHoldingsChains(): ChainConfig[] {
    const userHoldFtAssetsChainIndexs = this.userHoldings?.map(
      (ftAsset) => ftAsset?.chainIndex.toLocaleLowerCase()
    );
    return this.rootStore.chainStore.chains.filter((chain) =>
      userHoldFtAssetsChainIndexs.includes(
        chain?.chainIndex.toLocaleLowerCase()
      )
    );
  }

  get accountName(): string | undefined {
    if (this.accountInfo) return this.accountInfo.name;
    return undefined;
  }

  get accountNameWithOs(): string | undefined {
    return this.accountInfo
      ? this.accountInfo.name.replaceAll(".os", "") + ".os"
      : undefined;
  }

  get noNetwork(): boolean {
    return true;
  }

  get needOnboardingHome(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Home);
  }

  get needOnboardingEb(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Eb);
  }

  get needOnboardingAssets(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Assets);
  }

  get needOnboardingPayment(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Payment);
  }

  get needOnboardingAiScore(): boolean {
    return this.onboardingTodos.includes(OnboardingType.AiScore);
  }

  get needOnboardingEbLeaderBoardRanking(): boolean {
    return this.onboardingTodos.includes(OnboardingType.EbLeaderBoardRanking);
  }

  get needOnboardingSell(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Sell);
  }
}
