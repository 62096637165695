import React, { useEffect } from "react";
import "./App.css";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Router } from "./router/Router";
import ToastContainer from "src/components/toast/ToastContainer";
import LoadingOverlay from "./components/loading/LoadingOverlay";
import Auth from "./components/auth/Auth";
import "react-loading-skeleton/dist/skeleton.css";
import tgManager from "./store/managers/tgManager";
import { WagmiProvider } from "wagmi";
import { wagmiConfig } from "./configs/wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const theme = {
  colors: {
    __kontos_blue: "#413DEC",
    __white: "#FFF",
    __deep_25: "#F5F5F6",
    __deep_50: "#EDEEF1",
    __deep_100: "#CCCFD2",
    __deep_200: "#B2B6BC",
    __deep_400: "#80868F",
    __deep_800: "#1A2535",
    __success: "#10CE5C",
    __error: "#FF1E2B",
    __warning: "#FAAD14",
  },
};

const queryClient = new QueryClient();

export const App: React.FC = observer(() => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // const isTelegramMiniApp = () => {
    //   return Object.keys(window).includes("TelegramWebviewProxy");
    // };

    if (tgManager.isTg()) {
      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-web-app.js";
      script.onload = () => {
        if (window.Telegram && window.Telegram.WebApp) {
          // window.Telegram.WebApp?.BackButton?.show();
          window.Telegram.WebApp?.expand();
        } else {
          console.error("Telegram WebApp object is not available.");
        }
      };
      script.onerror = () => {
        console.error("Failed to load the Telegram WebApp script.");
      };
      document.head.appendChild(script);
    }
  }, []);

  return (
    <ThemeProvider theme={{ ...theme, mode: i18n.language || "en" }}>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <LoadingOverlay />
          <Auth />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </QueryClientProvider>
      </WagmiProvider>
    </ThemeProvider>
  );
});

export default App;
